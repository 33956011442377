import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../_services/auth.service";
import { AccountService } from "../_services/account.service";
import { catchError, map, Observable, of, switchMap, take, timeout } from "rxjs";

@Injectable({
    providedIn: 'root'
})

class PermissionsService {
    constructor(
        private router: Router,
        private authService: AuthService,
        private accountService: AccountService
    ) { }

    // ======================================================================== //
    // ================================ USER ================================== //
    // ======================================================================== //

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.userIsLoggedIn()) {
            return true;
        }

        this.router.navigate(['/login']);
        // Keep line below here for future, when behaviour for login is confirmed
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        // ^^ When user is prompted to login and does it, they will be redirected to where they left off.

        return false;
    }


    // ======================================================================== //
    // ============================== CONTENT ================================= //
    // ======================================================================== //
    canActivateNew(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const standardUrlPath = state.url.split('/');
        standardUrlPath.pop();

        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_MAKER_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }

                this.router.navigate([`${standardUrlPath.join('/')}/listing`]);
                return false;
            })
        )
    }

    canActivateExisting(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const standardUrlPath = state.url.split('/');
        standardUrlPath.pop();

        // Content Order page need to remove ONE more url path
        if (standardUrlPath.includes("order")) {
            standardUrlPath.pop();
        }

        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_MAKER_ADMIN' || role === 'ROLE_CHECKER_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate([`${standardUrlPath.join('/')}/listing`]);
                return false;
            })
        )
    }

    canActivateListing(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_MAKER_ADMIN' || role === 'ROLE_CHECKER_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate(['/login']);
                return false;
            })
        )

    }

    canActivateAssetLibrary(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_MAKER_ADMIN' || role === 'ROLE_CHECKER_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate(['/login']);
                return false;
            })
        )
    }

    canActivatePendingApproval(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_CHECKER_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate(['/login']);
                return false;
            })
        )

    }

    // ======================================================================== //
    // =============================== OTHERS ================================= //
    // ======================================================================== //
    canActivateReport(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // const adminRole = this.accountService.adminRole;

        // if (adminRole === 'ROLE_SECURITY_ADMIN') {
        //     this.router.navigate(['/manage-admins/all-admins']);
        //     return false;
        // }
        return true;
    }

    canActivateManagePartner(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_MAKER_ADMIN' || role === 'ROLE_CHECKER_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate(['/login']);
                return false;
            })
        )
    }

    canActivateManageAdmin(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_SECURITY_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate(['/login']);
                return false;
            })
        )
    }

    canActivateAuditTrail(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Get admin role
        return this.accountService.role$.pipe(
            timeout(5000),
            take(1),
            switchMap(role => {
                if (role) {
                    return of({ role });
                } else {
                    return this.accountService.getAdminProfile().pipe(take(1));
                }
            }),
            catchError(() => {
                return of({ role: null, allowedLanguages: null });
            }),
            map(({ role }) => {
                if (role === 'ROLE_SECURITY_ADMIN' || role === 'ROLE_SUPER_ADMIN') {
                    return true;
                }
                this.router.navigate(['/login']);
                return false;
            })
        )
    }
}

export const userAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(next, state);
};

export const newContentAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateNew(next, state);
};

export const existingContentAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateExisting(next, state);
};

export const listingContentAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateListing(next, state);
};

export const assetLibraryAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateAssetLibrary(next, state);
};

export const pendingApprovalAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivatePendingApproval(next, state);
};

export const reportAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateReport(next, state);
};

export const managePartnerAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateManagePartner(next, state);
};

export const manageAdminAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateManageAdmin(next, state);
};

export const auditTrailAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateAuditTrail(next, state);
};